import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { Observable } from 'babylonjs';
import * as yaml from 'js-yaml';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class YamlService {
    _backendData: WritableSignal<any> = signal(null);

    constructor(private http: HttpClient) {}

    get backendData() {
        return this._backendData();
    }

    loadYaml(url: string) {
        return this.http
            .get(url, { responseType: 'text' })
            .pipe(map(yamlContent => yaml.load(yamlContent)));
    }

    convertYamlToJson(yamlContent: string): any {
        try {
            const doc = yaml.load(yamlContent);
            return doc;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    getBackendData() {
        this.loadYaml('assets/yaml/openapi.yaml').subscribe(data => {
            this._backendData.set(data);
        });
    }
}
