import { Overlay } from '@angular/cdk/overlay';
import {
    Component,
    OnDestroy,
    Signal,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SELECT_SCROLL_STRATEGY, MatSelectModule } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { DataManagement, DataManagementFieldsInner } from 'app/api';
import { AlertService } from 'app/core/alert/alert.service';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import {
    ContentLayoutWrapperComponent,
    ContentLayoutWrapperConfig,
} from 'app/core/ui/content-layout-wrapper/content-layout-wrapper.component';
import { DataTableService } from 'app/modules/data-table/data-table.service';
import { DataManagementService } from 'app/modules/data/data-management.service';
import { DataTreeComponent } from 'app/modules/data/data-tree/data-tree.component';
import { ObjectExplorerService } from 'app/modules/object-explorer/object-explorer.service';
import { Subject, takeUntil } from 'rxjs';
import {
    GridViewsModule,
    TableColumnDef,
} from '../../../../../../../../../../projects/grid-views/src/public-api';

@Component({
    selector: 'select-transaction-dialog',
    standalone: true,
    imports: [
        DataTreeComponent,
        ContentLayoutWrapperComponent,
        GridViewsModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        FormlyModule,
        FormlyMaterialModule,
        ReactiveFormsModule,
    ],
    templateUrl: './select-transaction-dialog.component.html',
    styleUrl: './select-transaction-dialog.component.scss',
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay],
        },
    ],
})
export class SelectTransactionDialogComponent
    extends DialogBuilderComponent<any>
    implements OnDestroy
{
    private _destroy: Subject<void> = new Subject();
    selectedDataManagement: WritableSignal<DataManagement> = signal(null);

    config: Signal<ContentLayoutWrapperConfig>;
    data: WritableSignal<any> = signal([]);
    displayedColumns: Signal<Array<TableColumnDef>>;
    dataSource: Signal<MatTableDataSource<any>>;

    form = new FormGroup({});

    fields: WritableSignal<FormlyFieldConfig[]> = signal([]);

    labelData: any = { labelProp: '' };

    constructor(
        private service: DataTableService,
        private _dataManagementService: DataManagementService,
        private objectExplorerService: ObjectExplorerService,
        private alertService: AlertService
    ) {
        super();

        const process = this.objectExplorerService
            .objectExplorerItems()
            .find(item => item.id === 'process');

        const dm = this._dataManagementService.selectedDataTable();

        if (!dm) {
            this.alertService.errorAlert('sapTableError', 'description', 'title');
            console.error('The SAP table has not been properly set in ObjectExplorer.');
            return;
        }
        this._dataManagementService.selectedDataTable = dm;

        this._dataManagementService.labelProp = {
            columnKey: dm.fields[0].titel,
            columnName: dm.fields[0].description,
        };

        this.config = computed(() => {
            return {
                rightSection: {
                    useLeftContentHeader: true,
                },
                leftSection: {
                    header: {
                        showToggleButton: true,
                    },
                },
            };
        });

        this.form.valueChanges.pipe(takeUntil(this._destroy)).subscribe(() => {
            const formControl: FormControl = this.form.controls['labelProp'];
            this._dataManagementService.labelProp = formControl.getRawValue();
        });

        effect(
            () => {
                const selectedModule = this._dataManagementService.selectedDataTable();

                this.setFields();

                const md = this.service
                    .data()
                    .find((dm: DataManagement) => dm.id === selectedModule.id);

                if (md) {
                    this.selectedDataManagement.set(md);

                    this.service.getDbData(selectedModule, true).subscribe((data: any) => {
                        if (data) {
                            this.data.set(data);
                        } else {
                            this.data.set(null);
                        }
                    });
                }
            },
            {
                allowSignalWrites: true,
            }
        );

        this.displayedColumns = computed(() =>
            this.selectedDataManagement()
                ? this.selectedDataManagement().fields.map((dmfi: DataManagementFieldsInner) => {
                      return {
                          columnName: dmfi.titel,
                          columnKey: dmfi.titel,
                          contentType: dmfi.dtype,
                          enableSorting: true,
                      };
                  })
                : []
        );
        this.dataSource = computed(
            () => new MatTableDataSource(this.data()) ?? new MatTableDataSource([])
        );
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    private previousTableId: string;

    refresh(): void {
        const selectedModule = this._dataManagementService.selectedDataTable();

        if (!this.previousTableId || this.previousTableId !== selectedModule.system) {
            this.previousTableId = selectedModule.system;
            this._dataManagementService.selectedRow = null;
            this.setFields();

            const md = this.service
                .data()
                .find((dm: DataManagement) => dm.id === selectedModule.id);

            if (md) {
                this.selectedDataManagement.set(md);

                this.service.getDbData(selectedModule, true).subscribe((data: any) => {
                    if (data) {
                        this.data.set(data);
                    } else {
                        this.data.set(null);
                    }
                });
            }
        }
    }

    onRowSelected($event: any): void {
        this._dataManagementService.selectedRow = $event[0];
    }

    setFields(): void {
        this.fields.set([
            {
                key: 'labelProp',
                type: 'select',
                props: {
                    label: 'Label prop',
                    required: true,
                    options: this.displayedColumns().map(colDef => {
                        return {
                            label: colDef.columnName,
                            value: colDef.columnKey,
                        };
                    }),
                },
            },
        ]);
    }
}
